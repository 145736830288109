import { Link } from 'react-router-dom';
import './style.scss';
import CircleIcon from '@mui/icons-material/Circle';
import logo from '../../assets/mentalniKompas_logo.png';

const Footer = () => {
  return (
    <footer>
      <div className="main">
        <div className="container">
          <div className="logo">
            <img src={logo} alt="Mentalni Kompas" />
          </div>
        </div>
        <div className="container">
          <h3>Korisni linkovi</h3>
          <Link to="/">Početna</Link>
          <Link to="usluge">Usluge</Link>
          <Link to="o-nama">O meni</Link>
          <Link to="prvi-korak">Prvi korak</Link>
          <Link to="novosti">Novosti</Link>
        </div>

        <div className="container">
          <h3>Kontakt</h3>
          {/* <a href="tel:+">091 234 56 78</a> */}
          <a href="mailto:jasmina@mentalni-kompas.com">
            jasmina@mentalni-kompas.com
          </a>
        </div>
        <div className="container">
          <h3>Pratite nas</h3>
          <Link to="/" target="_blank">
            Facebook
          </Link>
          <Link to="//www.instagram.com/mentalnikompas/" target="_blank">
            Instagram
          </Link>
          <Link to="/" target="_blank">
            LinkedIn
          </Link>
        </div>
      </div>
      <div className="bottom">
        <p>
          <div>
            &copy; Mentalni Kompas 2023. <CircleIcon />
          </div>
          <div>
            developed with 💙 by{' '}
            <Link to="//www.livens.co" target="_blank">
            livens
            </Link>
          </div>
        </p>

        <div className="legal">
          <Link to="politika-privatnosti">privatnost</Link>|
          <Link to="kolacici">kolačići</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
